import React, { useState, useEffect } from "react";
import styles from "../css/Index.module.css";
import axios from "axios";
import Navbar from "../components/Navbar";
import logo from "../backgrounds/AIKs.webp";
import Footer from "../components/Footer";
import dayjs from "dayjs";
import MatchForm from "../components/MatchForm";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet-async";
import XMLParser from "react-xml-parser";
import { Link } from "react-router-dom";
import "dayjs/locale/sv";
import { Timeline } from "react-twitter-widgets";

function Index() {
  dayjs.locale("sv");
  const [press, setPress] = useState([]);
  const [clubData, setClubData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loaded2, setLoaded2] = useState(false);
  const [loaded3, setLoaded3] = useState(false);
  const [transfers, setTransfers] = useState([]);
  const [competition, setCompetition] = useState("Friendlies Clubs");
  const [comingGames, setComingGames] = useState([]);

  const [nextGameAwayTeamState, setNextGameAwayTeamState] = useState("");
  const [nextGameHomeTeamState, setNextGameHomeTeamState] = useState("");
  const [nextGameFixtureId, setNextGameFixtureId] = useState(0);

  const [h2hData, setH2hData] = useState([]);

  const [table, setTable] = useState([]);
  const [fixtures, setFixtures] = useState([]);
  const [fixtures2, setFixtures2] = useState([]);
  const [allsvenskanFixtures, setAllsvenskanFixtures] = useState({});

  const [selectedSeason, setSelectedSeason] = useState("2024");

  const [form, setForm] = useState("");

  const [venueData, setVenueData] = useState([]);

  const [matchData, setMatchData] = useState([]);

  function fetchFixtureData(fixtureId) {
    const options5 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/matchData",
      params: { fixture: fixtureId },
    };

    return axios.request(options5);
  }

  function filterPlayedAllsvenskanGames(data) {
    const currentDate = new Date();

    return data.filter((game) => {
      const gameDate = new Date(game.fixture.date);
      return game.league.name === "Allsvenskan" && gameDate < currentDate;
    });
  }

  useEffect(() => {

      // NEXT  GAME Doesnt work if there is no next game


    const options43 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/nextGame",
      params: { next: "1", team: 377 },
    };

    axios
      .request(options43)
      .then(function (response) {
        setComingGames(response.data.response);
        setNextGameAwayTeamState(response.data.response[0].teams.away.id);
        setNextGameHomeTeamState(response.data.response[0].teams.home.id);
        setNextGameFixtureId(response.data.response[0].fixture.id);
        // console.log(response.data.response[0].fixture.id);

        setLoaded2(true);
      })
      .catch(function (error) {
        console.error(error);
      });
     const options = {
        method: 'GET',
        url: 'https://aikstats-backend.onrender.com/teamTransfers',
        params: {team: '377'},
      };
      
      axios.request(options).then(function (response) {
       // console.log(response.data.response);
        setTransfers(response.data.response)
      }).catch(function (error) {
        console.error(error);
      }); 
      
    const cision = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/pressReleaser",
    };

    axios
      .request(cision)
      .then(function (response) {
        var xml = new XMLParser().parseFromString(response.data);
        setPress(xml.children[0].children);
        setLoaded3(true);
      })
      .catch(function (error) {
        console.error(error);
      });

    const options4 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/fixtures",
      params: { team: "377", season: 2023 },
    };

    axios
      .request(options4)
      .then(function (response) {
        setFixtures2(response.data.response);
        //console.log(response.data.response)
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const options31 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/h2h",
      params: { h2h: `${nextGameAwayTeamState}-${nextGameHomeTeamState}` },
    };
    // {h2h: `${nextGameAwayTeamState}-${nextGameHomeTeamState}`}
    axios
      .request(options31)
      .then(function (response) {
        //console.log(response.data);
        setH2hData(response.data.response);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [nextGameAwayTeamState]);
  useEffect(() => {
    const options = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/statistics",
      params: { league: "113", season: selectedSeason, team: "377" },
    };

    axios
      .request(options)
      .then(function (response) {
        const data = response.data;
        setClubData(data.response);
        setLoaded(true);

        setForm(data.response.form.slice(-5));
      })
      .catch(function (error) {
        console.error(error);
      });

    const options2 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/standings",
      params: { league: "113", season: selectedSeason },
    };

    axios
      .request(options2)
      .then(function (response) {
        setTable(response.data.response[0].league.standings[0]);
      })
      .catch(function (error) {
        console.error(error);
      });

    const options3 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/teams",
      params: { id: "377" },
    };

    axios
      .request(options3)
      .then(function (response) {
        const venueData = response.data.response[0].venue;
        setVenueData(venueData);
      })
      .catch(function (error) {
        console.error(error);
      });

    const options4 = {
      method: "GET",
      url: "https://aikstats-backend.onrender.com/fixtures",
      params: { team: "377", season: selectedSeason },
    };

    axios
      .request(options4)
      .then(function (response) {
        setFixtures(response.data.response);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [selectedSeason]);

  useEffect(() => {
    async function fetchAllsvenskanGames() {
      const options4 = {
        method: "GET",
        url: "https://aikstats-backend.onrender.com/fixtures",
        params: { team: "377", season: 2023 },
      };

      try {
        const response = await axios.request(options4);
        const playedAllsvenskanGames = filterPlayedAllsvenskanGames(
          response.data.response
        );
        setAllsvenskanFixtures(playedAllsvenskanGames);
        //console.log(playedAllsvenskanGames);

        const matchDataPromises = playedAllsvenskanGames.map((game) =>
          fetchFixtureData(game.fixture.id)
        );

        const matchDataResponses = await Promise.all(matchDataPromises);
        const matchData = matchDataResponses.map(
          (response) => response.data.response
        );
        setMatchData(matchData);
        // console.log(matchData);
        setLoaded3(true);
      } catch (error) {
        console.error(error);
      }
    }

    fetchAllsvenskanGames();
  }, []);

  if (loaded === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  //leftside data
  const mainLeague = clubData.league.name;

  const goals = clubData.goals.for.total.total + 0;
  const homeGoals = clubData.goals.for.total.home + 0;
  const awayGoals = clubData.goals.for.total.away + 0;
  const average = clubData.goals.for.average.total + 0;
  const averageHome = clubData.goals.for.average.home + 0;
  const averageAway = clubData.goals.for.average.away + 0;

  const goalsAgainst = clubData.goals.against.total.total + 0;
  const homeGoalsAgainst = clubData.goals.against.total.home + 0;
  const awayGoalsAgainst = clubData.goals.against.total.away + 0;
  const averageAgainst = clubData.goals.against.average.total + 0;
  const averageHomeAgainst = clubData.goals.against.average.home + 0;
  const averageAwayAgainst = clubData.goals.against.average.away + 0;

  const scoredPenalties = clubData.penalty.scored.total + 0;
  const missedPenalties = clubData.penalty.missed.total + 0;
  const penaltiesPercentage = clubData.penalty.scored.percentage;

  const wonHome = clubData.fixtures.wins.home + 0;
  const wonAway = clubData.fixtures.wins.away + 0;
  const losesHome = clubData.fixtures.loses.home + 0;
  const losesAway = clubData.fixtures.loses.away + 0;
  const drawsHome = clubData.fixtures.draws.home + 0;
  const drawsAway = clubData.fixtures.draws.away + 0;

  const lineups = clubData.lineups;
  const allsvenskanImage = clubData.league.logo;

  const cleansheetHome = clubData.clean_sheet.home + 0;
  const cleansheetAway = clubData.clean_sheet.away + 0;
  const cleansheetTotal = clubData.clean_sheet.total + 0;

  // venue data

  const arena = venueData.name;
  const address = venueData.address;
  const capacity = venueData.capacity;
  const surface = venueData.surface;

  // coming games data

  if (loaded2 === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  // NEXT GaME functions doesnt work without neext game

  const nextGameLogo = comingGames[0].league.logo;
  const nextGameVenue = comingGames[0].fixture.venue.name;
  const nextGameAwayTeam = comingGames[0].teams.away.name;
  const nextGameHomeTeam = comingGames[0].teams.home.name;
  const nextGameDate = comingGames[0].fixture.date;
  const datey = new Date(nextGameDate); 

  function addHours(date, hours) {
    const dateCopy = new Date(date);

    dateCopy.setHours(dateCopy.getHours() + hours);

    return dateCopy;
  }

  const date = new Date(datey);
  const newDate = addHours(date, 1);
  const summerTimeNewDate = addHours(date, 2);
  const summerTimeStringDate = JSON.stringify(summerTimeNewDate);
  const stringDate = JSON.stringify(newDate);
  const formattedDate = dayjs(newDate)
    .format("dddd D MMMM")
    .replace(/^\w/, (c) => c.toUpperCase());

    
    function getTimeString(date) {
      const currentDate = new Date(date);
      const summerStartDate = new Date(currentDate.getFullYear(), 2, 31); // March 31st
      const summerEndDate = new Date(currentDate.getFullYear(), 9, 27); // October 27th

      if (currentDate >= summerStartDate && currentDate <= summerEndDate) {
        return summerTimeStringDate.slice(12, 17);
      } else {
        return stringDate;
      }
    }

  let groupedFixtures = fixtures.reduce((groupedFixtures, fixture) => {
    const name = fixture.league.name;
    if (groupedFixtures[name] == null) groupedFixtures[name] = [];
    groupedFixtures[name].push(fixture);
    return groupedFixtures;
  }, []);
  let groupedFixtures2 = fixtures2.reduce((groupedFixtures2, fixture2) => {
    const name = fixture2.league.name;
    if (groupedFixtures2[name] == null) groupedFixtures2[name] = [];
    groupedFixtures2[name].push(fixture2);
    return groupedFixtures2;
  }, []);

  let totalRedCards = 0;
  if (clubData.cards && clubData.cards.red) {
    const redCards = clubData.cards.red;
    for (const timeSpan in redCards) {
      if (redCards[timeSpan].total === null) continue;
      totalRedCards += redCards[timeSpan].total;
    }
  }

  let totalYellowCards = 0;
  if (clubData.cards && clubData.cards.yellow) {
    const yellowCards = clubData.cards.yellow;
    for (const timeSpan in yellowCards) {
      if (yellowCards[timeSpan].total === null) continue;
      totalYellowCards += yellowCards[timeSpan].total;
    }
  }
  // Press data
  if (loaded3 === false) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const pressTitle1 = press[5].children[0].value;
  const pressLink1 = press[5].children[1].value;
  const pressDescription1 = press[5].children[3].value;
  const pressDate = press[5].children[4].value;

  const handleClick = () => {
    window.open(`${pressLink1}`);
  };
  const pressTitle2 = press[6].children[0].value;
  const pressLink2 = press[6].children[1].value;
  const pressDescription2 = press[6].children[3].value;
  const pressDate2 = press[6].children[4].value;

  const handleClick2 = () => {
    window.open(`${pressLink2}`);
  };
  const pressTitle3 = press[7].children[0].value;
  const pressLink3 = press[7].children[1].value;
  const pressDescription3 = press[7].children[3].value;
  const pressDate3 = press[7].children[4].value;

  const handleClick3 = () => {
    window.open(`${pressLink3}`);
  };
  const pressTitle4 = press[8].children[0].value;
  const pressLink4 = press[8].children[1].value;
  const pressDescription4 = press[8].children[3].value;
  const pressDate4 = press[8].children[4].value;

  const handleClick4 = () => {
    window.open(`${pressLink4}`);
  };
  const pressTitle5 = press[9].children[0].value;
  const pressLink5 = press[9].children[1].value;
  const pressDescription5 = press[9].children[3].value;
  const pressDate5 = press[9].children[4].value;

  const handleClick5 = () => {
    window.open(`${pressLink5}`);
  };
  const dateye = new Date();

  const h2hDataFiltered = h2hData.filter((h2h) =>
    dayjs(dateye).isAfter(dayjs(h2h.fixture.date))
  );

  function calculatePlayerMinutes(matchData, playerIds) {
    const playerMinutes = {};

    if (!Array.isArray(matchData)) {
      console.error("Error: matchData is not an array");
      return playerMinutes;
    }

    if (!Array.isArray(playerIds)) {
      console.error("Error: playerIds is not an array");
      return playerMinutes;
    }

    // Initialize player minutes object
    playerIds.forEach((playerId) => {
      playerMinutes[playerId] = 0;
    });

    // Iterate through the match data
    matchData.forEach((match, matchIndex) => {
      if (!match || !Array.isArray(match)) {
        console.error("Error: Invalid match data");
        return;
      }

      // Iterate through each team in the match
      match.forEach((team, teamIndex) => {
        if (!team.players) {
          console.error("Error: Invalid team data");
          return;
        }

        team.players.forEach((playerData) => {
          const playerId = playerData.player.id;
          const minutesPlayed = playerData.statistics[0].games.minutes;

          // If the player is in the list of specific player IDs, add their minutes
          if (playerIds.includes(playerId)) {
            playerMinutes[playerId] += minutesPlayed;
          }
        });
      });
    });

    return playerMinutes;
  }
  const specificPlayerIds = [198656, 207203, 360565, 378193, 338949, 375545, 436443];
  const playerMinutesPlayed = calculatePlayerMinutes(
    matchData,
    specificPlayerIds
  );
  // console.log(playerMinutesPlayed);
  function getAveragePlayersPerGame(matchData, playerIds) {
    let totalPlayers = 0;

    matchData.forEach((match, matchIndex) => {
      if (!match || !Array.isArray(match)) {
        console.error("Error: Invalid match data");
        return;
      }

      let playersInCurrentGame = new Set();

      match.forEach((team, teamIndex) => {
        if (!team.players) {
          console.error("Error: Invalid team data");
          return;
        }

        team.players.forEach((playerData) => {
          const playerId = playerData.player.id;
          const minutesPlayed = playerData.statistics[0].games.minutes;

          if (playerIds.includes(playerId) && minutesPlayed > 0) {
            playersInCurrentGame.add(playerId);
          }
        });
      });

      totalPlayers += playersInCurrentGame.size;
    });

    return totalPlayers / matchData.length;
  }

  function getTotalMinutes(playerMinutes) {
    return Object.values(playerMinutes).reduce(
      (total, minutes) => total + minutes,
      0
    );
  }

  function getAverageMinutes(playerMinutes) {
    const totalMinutes = getTotalMinutes(playerMinutes);
    const playerCount = Object.keys(playerMinutes).length;
    return totalMinutes / playerCount;
  }

  function getPlayersWithMinutes(playerMinutes) {
    let count = 0;
    for (const minutes of Object.values(playerMinutes)) {
      if (minutes > 0) {
        count++;
      }
    }
    return count;
  }

  function getAveragePlaytimePerGame(matchData, playerIds) {
    let totalMinutes = 0;
    let totalGames = 0;

    matchData.forEach((match, matchIndex) => {
      if (!match || !Array.isArray(match)) {
        console.error("Error: Invalid match data");
        return;
      }

      match.forEach((team, teamIndex) => {
        if (!team.players) {
          console.error("Error: Invalid team data");
          return;
        }

        team.players.forEach((playerData) => {
          const playerId = playerData.player.id;
          const minutesPlayed = playerData.statistics[0].games.minutes;

          if (playerIds.includes(playerId) && minutesPlayed > 0) {
            totalMinutes += minutesPlayed;
            totalGames++;
          }
        });
      });
    });

    return totalMinutes / totalGames;
  }

  const totalMinutes = getTotalMinutes(playerMinutesPlayed);
  const averageMinutes = getAverageMinutes(playerMinutesPlayed);
  const playersWithMinutes = getPlayersWithMinutes(playerMinutesPlayed);
  const averagePlayersPerGame = getAveragePlayersPerGame(
    matchData,
    specificPlayerIds
  );
  const avgPlaytimePerGame = getAveragePlaytimePerGame(
    matchData,
    specificPlayerIds
  );
  const roundedAvgPlaytimePerGame = avgPlaytimePerGame.toFixed(2);

  const publiksnitt = [32178, 41142, 23502, 21113, 20176, 19671, 22207, 26736, 24647, 19164, 21053, 41327, 25641, 23213, 24327];

  const calculateAverage = (arr) => {
    const sum = arr.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return Math.ceil(sum / arr.length);
  };

  const averagePubliksnitt = calculateAverage(publiksnitt);

  return (
    <div className={styles.parent}>
      <Helmet>
        <title>
          AIKstatistik.se - Statistik, fakta, spelschema & tabell | AIK fotboll
        </title>
      </Helmet>
      <div className={styles.leftSide}>
        <h1 className={styles.playerName}>
          {" "}
          <Navbar />{" "}
        </h1>
        <div className={styles.border}></div>
        <h1 className={styles.aikStats}>AIK Statistik</h1>
        <p className={styles.catchPhrase}>
          Den ultimata källan för AIK fotbollsspelarstatistik
        </p>

        <img
          className={styles.emblemImage}
          src={logo}
          width="250px"
          height="250px"
          alt="AIK logo"
        />
        <div className={styles.dataBox}>
          <p className={styles.dataText}>
            Välkommen till AIK statistik som fokuserar på statistik för
            fotbollsspelare inom AIK fotboll. Här hittar du in-depth data för
            alla spelare i AIK fotboll och våra mer spännande exporter som av
            olika anledningar kan vara kul att följa.
            <br></br>
            <br></br>
            Oavsett om du är en diehard-fan som vill ha all information om dina
            favoritspelare eller en analytiker som letar efter insikter och
            trender, har vi det du behöver. Vi har samlat all den information du
            behöver för att få en djupare förståelse för hur dina favoritspelare
            presterar på planen.
            <br></br>
            <br></br>
            Här på startsidan har vi samlat spelscheman för de olika tävlingar
            AIK fotboll är med i, men även statistik för hur AIK presterat i
            Allsvenskan för flera år tillbaka och annat smått och gott. Längst
            ner når ni även vårt Twitterkonto som ni gärna får följa.
            <br></br>
            <br></br>
            Gjort en sida som håller koll på inbördes möten mellan oss och våra
            rivaler i Stockholm samt följer deras statistik för året i
            Allsvenskan. Ta dig dit via menyn eller klicka{" "}
            <Link className={styles.indexLink} to="/derbyStatistik">
              här.
            </Link>
            <br></br>
            <br></br>
            Besök oss ofta för att hålla dig uppdaterad med den senaste
            statistisken kring våra spelare i AIK fotboll. Tack för att du
            besöker oss!
          </p>
        
<div id="ezoic-pub-ad-placeholder-101"> </div>

          <div className={styles.border}></div>
        </div>
        <h3 className={styles.bigMenu}>
          {" "}
          {mainLeague}{" "}
          <img
            className={styles.cupLogo}
            src={allsvenskanImage}
            alt="League logo"
          ></img>
        </h3>

        <p className={styles.season}>
          Säsong:{" "}
          <select
            className={styles.customSelect}
            onChange={(e) => setSelectedSeason(e.target.value)}
          >
            <option value="2024">2024</option>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
            <option value="2018">2018</option>
            <option value="2017">2017</option>
            <option value="2016">2016</option>
          </select>{" "}
        </p>
        <MatchForm data={form} className={styles.position} />
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Gjorda mål</h3>
        <div className={styles.flexbox}>
          <p>Hemma: {homeGoals} </p>
          <p>Borta: {awayGoals} </p>
          <p>Totalt: {goals} </p>
        </div>
        <div className={styles.flexbox}>
          <p>Snitt H: {averageHome}</p>
          <p>Snitt B: {averageAway} </p>
          <p>Snitt T: {average} </p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Insläppta mål</h3>
        <div className={styles.flexbox}>
          <p>Hemma: {homeGoalsAgainst}</p>
          <p>Borta: {awayGoalsAgainst}</p>
          <p>Totalt: {goalsAgainst} </p>
        </div>
        <div className={styles.flexbox}>
          <p>Snitt H: {averageHomeAgainst}</p>
          <p>Snitt B: {averageAwayAgainst} </p>
          <p>Snitt T: {averageAgainst} </p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Straffar</h3>
        <div className={styles.flexbox}>
          <p>Straffmål: {scoredPenalties}</p>
          <p>Missade: {missedPenalties} </p>
          <p>Procent: {penaltiesPercentage} </p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Matcher</h3>
        <div className={styles.flexbox}>
          <p>Vunna H: {wonHome}</p>
          <p>Vunna B: {wonAway}</p>
        </div>
        <div className={styles.flexbox}>
          <p>Förlorade H: {losesHome}</p>
          <p>Förlorade B: {losesAway}</p>
        </div>
        <div className={styles.flexbox}>
          <p>Kryss H: {drawsHome}</p>
          <p>Kryss B: {drawsAway}</p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Hållna nollor</h3>
        <div className={styles.flexbox}>
          <p>Hemma: {cleansheetHome} </p>
          <p>Borta: {cleansheetAway} </p>
          <p>Totalt: {cleansheetTotal} </p>
        </div>
        <div className={styles.border}></div>
        <h3 className={styles.bigMenu}>Kort</h3>
        <div className={styles.flexbox}>
          <p>Röda: {totalRedCards} </p>
          <p>Gula: {totalYellowCards} </p>
        </div>
        <div className={styles.border}></div>
        <h1 className={styles.uppstallningar}>Uppställningar</h1>
        {lineups.map((lineup) => {
          return (
            <p key={crypto.randomUUID()} className={styles.lineupP}>
              {lineup.formation} - {lineup.played} gånger
            </p>
          );
        })}
        <div className={styles.border}></div>
        <h2 className={styles.uppstallningar}>Övergångsspelare</h2>
        <h2 className={styles.uppstallningar}>
          <span style={{ fontSize: "2rem" }}>2023</span>
        </h2>
        <p className={styles.transitPlayerText}>
          I AIK är målet att varje startelva ska bestå av minst 3
          övergångsspelare med 90 minuters speltid för att uppnå 8100 minuter på
          30 matcher. Här kan du följa hur det går under 2023.
        </p>
        <p className={styles.transPlayerTitle}>
          Totala minuter:{" "}
          <span className={styles.yellowSpan}>{totalMinutes}</span>
        </p>
        <p className={styles.transPlayerTitle}>
          Genomsn. speltid/spelare per match:{" "}
          <span className={styles.yellowSpan}>{roundedAvgPlaytimePerGame}</span>
        </p>
        <p className={styles.transPlayerTitle}>
          Total överg. spelare m. speltid:{" "}
          <span className={styles.yellowSpan}>{playersWithMinutes}</span>
        </p>
        <p className={styles.transPlayerTitle}>
          Genomsn. överg. spelare/match:{" "}
          <span className={styles.yellowSpan}>{averagePlayersPerGame.toFixed(1)}</span>
        </p>
        <div className={styles.border}></div>
        <h2 className={styles.uppstallningar}>Publiksnitt 2023</h2>
        <h2 className={styles.uppstallningar}>
          <span style={{ fontSize: "1rem", color: "white" }}>{averagePubliksnitt}</span>
        </h2>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.infoBox}>
          <img
            className={styles.emblemImage}
            src={logo}
            height="220px"
            width="220px"
            alt="AIK logo"
          />
          <h1 className={styles.aikStats}>AIK Statistik</h1>
          <h1 className={styles.info}>Info</h1>
          <p className={styles.catchPhrase}>
            Den ultimata källan för AIK fotbollsspelarstatistik
          </p>
          <div className={styles.borderBig}></div>
          <h6 className={styles.signing}>
            Välkommen till AIK statistik som fokuserar på statistik för
            fotbollsspelare inom AIK fotboll. Här hittar du in-depth data för
            alla spelare i AIK fotboll och våra mer spännande exporter som av
            olika anledningar kan vara kul att följa.
            <br></br>
            <br></br>
            Oavsett om du är en diehard-fan som vill ha all information om dina
            favoritspelare eller en analytiker som letar efter insikter och
            trender, har vi det du behöver. Vi har samlat all den information du
            behöver för att få en djupare förståelse för hur dina favoritspelare
            presterar på planen.
            <br></br>
            <br></br>
            Här på startsidan har vi samlat spelscheman för de olika tävlingar
            AIK fotboll är med i, men även statistik för hur AIK presterat i
            Allsvenskan för flera år tillbaka och annat smått och gott. Längst
            ner når ni även vårt Twitterkonto som ni gärna får följa.
            <br></br>
            <br></br>
            Gjort en sida som håller koll på inbördes möten mellan oss och våra
            rivaler i Stockholm samt följer deras statistik för året i
            Allsvenskan. Ta dig dit via menyn eller klicka{" "}
            <Link className={styles.indexLink} to="/derbyStatistik">
              här.
            </Link>
            <br></br>
            <br></br>
            Besök oss ofta för att hålla dig uppdaterad med den senaste
            statistisken kring våra spelare i AIK fotboll. Tack för att du
            besöker oss!
          </h6>
        </div>

        <div className={styles.rightSideFixtures}>
          <h1 className={styles.matcherH1}>Spelschema</h1>
          <div className={styles.tableBox}>
            <table className={styles.tableSchema}>
              <thead className={styles.middleTable}>
                <tr>
                  <th colspan="5" className={styles.bottomBorder}>
                    Allsvenskan
                  </th>
                </tr>
              </thead>
              <thead className={styles.middleTable2}>
                <tr>
                  <th className={`${styles.headerBorder}`}>Omgång</th>
                  <th className={`${styles.headerBorder}`}>Hemmalag</th>
                  <th className={`${styles.headerBorder}`}>Resultat</th>
                  <th className={`${styles.headerBorder}`}>Bortalag</th>
                  <th className={styles.rightTable}>Datum</th>
                </tr>
              </thead>
              <tbody>
                {groupedFixtures.Allsvenskan &&
                  groupedFixtures.Allsvenskan.sort((a, b) => {
                    return a.fixture.timestamp - b.fixture.timestamp;
                  }).map((fixture) => {
                    return (
                      <tr key={crypto.randomUUID()}>
                        <td
                          className={`${styles.noLeftBorder} ${styles.tableTurn} ${styles.schemaTableBorder}`}
                        >
                          {fixture.league.round.slice(16)}.
                        </td>
                        <td className={styles.schemaTableBorder}>
                          {(() => {
                            if (fixture.teams.home.name === "AIK stockholm") {
                              return fixture.teams.home.name.slice(0, -10);
                            } else if (
                              fixture.teams.home.name === "AIK Stockholm"
                            ) {
                              return fixture.teams.home.name.slice(0, -10);
                            } else if (
                              fixture.teams.home.name === "IF Brommapojkarna"
                            ) {
                              return "BP";
                            } else {
                              return fixture.teams.home.name;
                            }
                          })()}
                        </td>
                        <td className={styles.schemaTableBorder}>
                          {fixture.score.fulltime.home} -{" "}
                          {fixture.score.fulltime.away}
                        </td>
                        <td className={styles.schemaTableBorder}>
                          {(() => {
                            if (fixture.teams.away.name === "AIK stockholm") {
                              return fixture.teams.away.name.slice(0, -10);
                            } else if (
                              fixture.teams.away.name === "AIK Stockholm"
                            ) {
                              return fixture.teams.away.name.slice(0, -10);
                            } else if (
                              fixture.teams.away.name === "IF Brommapojkarna"
                            ) {
                              return "BP";
                            } else {
                              return fixture.teams.away.name;
                            }
                          })()}
                        </td>
                        <td className={styles.schemaTableBorder}>
                          {dayjs(fixture.fixture.date).format("YY.MM.DD")}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {selectedSeason === "2024" ? (
            <div className={styles.tableBox}>
              <table className={styles.tableSchema}>
                <thead className={styles.middleTable}>
                  <tr>
                    <th colspan="5" className={styles.bottomBorder}>
                      Svenska Cupen
                    </th>
                  </tr>
                </thead>
                <thead className={styles.middleTable2}>
                  <tr>
                    <th className={`${styles.headerBorder}`}>Omgång</th>
                    <th className={`${styles.headerBorder}`}>Hemmalag</th>
                    <th className={`${styles.headerBorder}`}>Resultat</th>
                    <th className={`${styles.headerBorder}`}>Bortalag</th>
                    <th className={styles.rightTable}>Datum</th>
                  </tr>
                </thead>
                <tbody>
                  {groupedFixtures2["Svenska Cupen"] &&
                    groupedFixtures2["Svenska Cupen"]
                      .sort((a, b) => {
                        return a.fixture.timestamp - b.fixture.timestamp;
                      })
                      .map((fixture) => {
                        return (
                          <tr key={crypto.randomUUID()}>
                            <td
                              className={`${styles.noLeftBorder} ${styles.schemaTableBorder}`}
                            >
                              {(() => {
                                if (
                                  fixture.league.round === "Club Friendlies 3"
                                ) {
                                  return "---";
                                } else if (
                                  fixture.teams.home.name ===
                                  "IF Brommapojkarna"
                                ) {
                                  return "BP";
                                } else {
                                  return fixture.league.round;
                                }
                              })()}
                            </td>
                            <td className={styles.schemaTableBorder}>
                              {(() => {
                                if (
                                  fixture.teams.home.name === "AIK stockholm"
                                ) {
                                  return fixture.teams.home.name.slice(0, -10);
                                } else if (
                                  fixture.teams.home.name === "AIK Stockholm"
                                ) {
                                  return fixture.teams.home.name.slice(0, -10);
                                } else if (
                                  fixture.teams.home.name ===
                                  "IF Brommapojkarna"
                                ) {
                                  return "BP";
                                } else {
                                  return fixture.teams.home.name;
                                }
                              })()}
                            </td>
                            <td className={styles.schemaTableBorder}>
                              {fixture.score.fulltime.home} -{" "}
                              {fixture.score.fulltime.away}
                            </td>
                            <td className={styles.schemaTableBorder}>
                              {(() => {
                                if (
                                  fixture.teams.away.name === "AIK stockholm"
                                ) {
                                  return fixture.teams.away.name.slice(0, -10);
                                } else if (
                                  fixture.teams.away.name === "AIK Stockholm"
                                ) {
                                  return fixture.teams.away.name.slice(0, -10);
                                } else if (
                                  fixture.teams.away.name ===
                                  "IF Brommapojkarna"
                                ) {
                                  return "BP";
                                } else {
                                  return fixture.teams.away.name;
                                }
                              })()}
                            </td>
                            <td className={styles.schemaTableBorder}>
                              {dayjs(fixture.fixture.date).format("YY.MM.DD")}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
          ) : (
            <div></div>
          )}

          <div className={styles.selectDiv}>
            <p className={styles.season}>
              {" "}
              <select
                className={styles.customSelect}
                onChange={(e) => setCompetition(e.target.value)}
              >
                <option value="Friendlies Clubs">Vänskapsmatcher</option>
                <option value="The Atlantic Cup">Atlantic Cup</option>
                <option value="UEFA Europa Conference League">
                  Europa Conference League
                </option>
                <option value="UEFA Europa League">Europa League</option>
                <option value="UEFA Champions League">Champions League</option>
              </select>{" "}
            </p>
          </div>
          <div className={styles.tableBox}>
            <table className={styles.tableSchema}>
              <thead className={styles.middleTable2}>
                <tr>
                  <th className={`${styles.headerBorder}`}>Omgång</th>
                  <th className={`${styles.headerBorder}`}>Hemmalag</th>
                  <th className={`${styles.headerBorder}`}>Resultat</th>
                  <th className={`${styles.headerBorder}`}>Bortalag</th>
                  <th className={styles.rightTable}>Datum</th>
                </tr>
              </thead>
              <tbody>
                {groupedFixtures[competition] &&
                  groupedFixtures[competition]
                    .sort((a, b) => {
                      return a.fixture.timestamp - b.fixture.timestamp;
                    })
                    .map((fixture) => {
                      return (
                        <tr key={crypto.randomUUID()}>
                          <td
                            className={`${styles.noLeftBorder} ${styles.schemaTableBorder}`}
                          >
                            {(() => {
                              if (
                                fixture.league.round === "Club Friendlies 3"
                              ) {
                                return "---";
                              } else if (
                                fixture.teams.home.name === "IF Brommapojkarna"
                              ) {
                                return "BP";
                              } else {
                                return fixture.league.round;
                              }
                            })()}
                          </td>
                          <td className={styles.schemaTableBorder}>
                            {(() => {
                              if (fixture.teams.home.name === "AIK stockholm") {
                                return fixture.teams.home.name.slice(0, -10);
                              } else if (
                                fixture.teams.home.name === "AIK Stockholm"
                              ) {
                                return fixture.teams.home.name.slice(0, -10);
                              } else if (
                                fixture.teams.home.name === "IF Brommapojkarna"
                              ) {
                                return "BP";
                              } else {
                                return fixture.teams.home.name;
                              }
                            })()}
                          </td>
                          <td className={styles.schemaTableBorder}>
                            {fixture.score.fulltime.home} -{" "}
                            {fixture.score.fulltime.away}
                          </td>
                          <td className={styles.schemaTableBorder}>
                            {(() => {
                              if (fixture.teams.away.name === "AIK stockholm") {
                                return fixture.teams.away.name.slice(0, -10);
                              } else if (
                                fixture.teams.away.name === "AIK Stockholm"
                              ) {
                                return fixture.teams.away.name.slice(0, -10);
                              } else if (
                                fixture.teams.away.name === "IF Brommapojkarna"
                              ) {
                                return "BP";
                              } else {
                                return fixture.teams.away.name;
                              }
                            })()}
                          </td>
                          <td className={styles.schemaTableBorder}>
                            {dayjs(fixture.fixture.date).format("YY.MM.DD")}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className={styles.farRightSide}>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />

        <div className={styles.border}></div>

       
       <h1 className={styles.factBox}>Nästa match</h1>

        <img src={nextGameLogo} alt="next game league" width="50px"></img>

        <h1 className={styles.nextGameTeams}>
          {" "}
          {(() => {
            if (nextGameHomeTeam === "AIK stockholm") {
              return (
                <span style={{ color: "#ffdd00" }}>
                  {nextGameHomeTeam.slice(0, -10)}
                </span>
              );
            } else if (nextGameHomeTeam === "AIK Stockholm") {
              return (
                <span style={{ color: "#ffdd00" }}>
                  {nextGameHomeTeam.slice(0, -10)}
                </span>
              );
            } else {
              return nextGameHomeTeam;
            }
          })()}{" "}
          -{" "}
          {(() => {
            if (nextGameAwayTeam === "AIK stockholm") {
              return (
                <span style={{ color: "#ffdd00" }}>
                  {nextGameAwayTeam.slice(0, -10)}
                </span>
              );
            } else if (nextGameAwayTeam === "AIK Stockholm") {
              return (
                <span style={{ color: "#ffdd00" }}>
                  {nextGameAwayTeam.slice(0, -10)}
                </span>
              );
            } else {
              return nextGameAwayTeam;
            }
          })()}
        </h1>
        <p className={styles.nextGameVenue}>{nextGameVenue}</p>
       

       

        <p className={styles.nextGameTime}>
          {getTimeString(formattedDate).slice(12, 17)}
        </p>
     {/*   <p>
          {summerTimeStringDate.slice(12, 17)}
        </p> */} 
        <p className={styles.nextGameDate}>{formattedDate}</p>
        <div className={styles.border}></div>
        <h5 className={styles.h2hTitle}>Senaste möten</h5>
        <table className={styles.h2hTable}>
          <tbody>
            {h2hDataFiltered

              .sort((a, b) => {
                return a.fixture.timestamp - b.fixture.timestamp;
              })
              .reverse()
              .slice(0, 5)
              .map((h2h) => {
                return (
                  <tr key={crypto.randomUUID()} className={styles.h2hP}>
                    <td className={`${styles.h2hHome} ${styles.cellBorder}`}>
                      {(() => {
                        if (h2h.teams.home.name === "AIK stockholm") {
                          return (
                            <span>{h2h.teams.home.name.slice(0, -10)}</span>
                          );
                        } else if (h2h.teams.home.name === "AIK Stockholm") {
                          return (
                            <span>{h2h.teams.home.name.slice(0, -10)}</span>
                          );
                        } else {
                          return h2h.teams.home.name;
                        }
                      })()}
                    </td>
                    <td className={styles.h2hResult}> {h2h.goals.home}</td>
                    <td>-</td>
                    <td className={`${styles.h2hResult} ${styles.cellBorder}`}>
                      {" "}
                      {h2h.goals.away}
                    </td>
                    <td className={`${styles.h2hAway} ${styles.cellBorder}`}>
                      {(() => {
                        if (h2h.teams.away.name === "AIK stockholm") {
                          return (
                            <span>{h2h.teams.away.name.slice(0, -10)}</span>
                          );
                        } else if (h2h.teams.away.name === "AIK Stockholm") {
                          return (
                            <span>{h2h.teams.away.name.slice(0, -10)}</span>
                          );
                        } else {
                          return h2h.teams.away.name;
                        }
                      })()}
                    </td>
                    <td className={styles.h2hDate}>
                      {dayjs(h2h.fixture.date).format("YY.MM.DD")}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className={styles.border}></div>
        <h1 className={styles.factBox}>Tabell</h1>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.headerCell}></th>
              <th className={styles.headerCell}></th>
              <th
                className={`${styles.headerCell} ${styles.cellSpacing}`}
                title="Spelade"
              >
                S
              </th>
              <th
                className={`${styles.headerCell} ${styles.cellSpacing}`}
                title="Vunna"
              >
                V
              </th>
              <th
                className={`${styles.headerCell} ${styles.cellSpacing}`}
                title="Oavgjorda"
              >
                O
              </th>
              <th
                className={`${styles.headerCell} ${styles.cellSpacing}`}
                title="Förlorade"
              >
                F
              </th>
              <th
                className={`${styles.headerCell} ${styles.cellSpacing}`}
                title="Gjorda Mål"
              >
                M
              </th>
              <th
                className={`${styles.headerCell} ${styles.cellSpacing}`}
                title="Insläppta Mål"
              >
                I
              </th>
              <th
                className={`${styles.headerCell} ${styles.cellSpacing}`}
                title="Poäng"
              >
                P
              </th>
            </tr>
          </thead>
          <tbody>
            {table.map((tab) => {
              return (
                <tr key={crypto.randomUUID()} className={styles.h2hP}>
                  <td className={styles.rankCell}>{tab.rank}.</td>
                  <td className={`${styles.tableTeam} ${styles.cellSpacing}`}>
                    {(() => {
                      if (tab.team.name === "AIK stockholm") {
                        return (
                          <span
                            className={styles.tableTeam}
                            style={{ color: "#ffdd00" }}
                          >
                            {tab.team.name.slice(0, -10)}
                          </span>
                        );
                      } else if (tab.team.name === "AIK Stockholm") {
                        return (
                          <span
                            className={styles.tableTeam}
                            style={{ color: "#ffdd00" }}
                          >
                            {tab.team.name.slice(0, -10)}
                          </span>
                        );
                      } else if (tab.team.name === "IF Brommapojkarna") {
                        return (
                          <span className={styles.tableTeam}>
                            Brommapojkarna
                          </span>
                        );
                      } else if (tab.team.name === "kalmar FF") {
                        return (
                          <span className={styles.tableTeam}>Kalmar FF</span>
                        );
                      } else {
                        return (
                          <span className={styles.tableTeam}>
                            {tab.team.name}
                          </span>
                        );
                      }
                    })()}
                  </td>
                  <td className={`${styles.tableResult} ${styles.cellSpacing}`}>
                    {tab.all.played}
                  </td>
                  <td className={`${styles.tableResult} ${styles.cellSpacing}`}>
                    {tab.all.win}
                  </td>
                  <td className={`${styles.tableResult} ${styles.cellSpacing}`}>
                    {tab.all.draw}
                  </td>
                  <td className={`${styles.tableResult} ${styles.cellSpacing}`}>
                    {tab.all.lose}
                  </td>
                  <td className={`${styles.tableResult} ${styles.cellSpacing}`}>
                    {tab.all.goals.for}
                  </td>
                  <td className={`${styles.tableResult} ${styles.cellSpacing}`}>
                    {tab.all.goals.against}
                  </td>
                  <td className={`${styles.tableResult} ${styles.cellSpacing}`}>
                    {tab.points}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className={styles.border}></div>
        <h1 className={styles.factBox}>Pressreleaser</h1>
        <div className={styles.border}></div>
        <div className={styles.pressDiv}>
          <p className={styles.pressLink}>
            {" "}
            <a className={styles.smallLinkMassan} onClick={handleClick} href="">
              {pressTitle1}
            </a>
          </p>
          <p className={styles.nationality}> </p>
          <p className={styles.nationality2}>
            {" "}
            {pressDescription1.substring(0, 130)}...{" "}
          </p>
          <p className={styles.nationality}>{pressDate.slice(4, 17)}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.pressDiv2}>
          <p className={styles.pressLink}>
            {" "}
            <a
              className={styles.smallLinkMassan}
              onClick={handleClick2}
              href=""
            >
              {pressTitle2}
            </a>
          </p>
          <p className={styles.nationality2}>
            {" "}
            {pressDescription2.substring(0, 130)}...{" "}
          </p>
          <p className={styles.nationality}>{pressDate2.slice(4, 17)}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.pressDiv}>
          <p className={styles.pressLink}>
            {" "}
            <a
              className={styles.smallLinkMassan}
              onClick={handleClick3}
              href=""
            >
              {pressTitle3}
            </a>
          </p>
          <p className={styles.nationality2}>
            {" "}
            {pressDescription3.substring(0, 130)}...{" "}
          </p>
          <p className={styles.nationality}>{pressDate3.slice(4, 17)}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.pressDiv2}>
          <p className={styles.pressLink}>
            {" "}
            <a
              className={styles.smallLinkMassan}
              onClick={handleClick4}
              href=""
            >
              {pressTitle4}
            </a>
          </p>
          <p className={styles.nationality2}>
            {" "}
            {pressDescription4.substring(0, 130)}...{" "}
          </p>
          <p className={styles.nationality}>{pressDate4.slice(4, 17)}</p>
        </div>
        <div className={styles.border}></div>
        <div className={styles.pressDiv}>
          <p className={styles.pressLink}>
            {" "}
            <a
              className={styles.smallLinkMassan}
              onClick={handleClick5}
              href=""
            >
              {pressTitle5}
            </a>
          </p>
          <p className={styles.nationality2}>
            {" "}
            {pressDescription5.substring(0, 130)}...{" "}
          </p>
          <p className={styles.nationality}>{pressDate5.slice(4, 17)}</p>
        </div>
       
        <div className={styles.border}></div>
        <h1 className={styles.factBox}>{arena}</h1>
        <p className={styles.nationality}> {address} </p>
        <p className={styles.nationality}>Kapacitet: {capacity} </p>
        <p className={styles.nationality}>
          Underlag:{" "}
          {(() => {
            if (surface === "grass") {
              return "Gräs";
            } else {
              return surface;
            }
          })()}
        </p>
        <div className={styles.border}></div>
        <img
          className={styles.logoImage}
          src={logo}
          height="50px"
          width="50px"
          alt="AIK logo"
        />
      </div>
      <div className={styles.footerDiv}>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
